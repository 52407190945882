.header_restrict {
  border-bottom: 1px solid @primary-color;
  &_container {
    padding: 0 15px;
    display: flex;
  }
  &_site_name {
    flex: 1;
    a {
      font-size: 14px;
      font-weight: 500;
      color: @black;
    }
  }
  &_title {
    display: flex;
    flex: 2;
    width: 100%;
    justify-content: center;
    position: relative;
    background: @white;
    .login_title {
      font-weight: 700;
      font-size: 30px;
      text-align: center;
    }
  }
  &_language {
    flex: 1;
  }
}
.restrict_container {
  background: @light-bg;
}

@media screen and (max-width: 768px) {
  .header_restrict {
    border-bottom: 1px solid @gray-1;
    &_title {
      position: absolute;
      top: 61px;
      height: 60px;
      left: 0;
      right: 0;
      z-index: 99;
      border-bottom: 1px solid @primary-color;
      background-color: @white;
      .login_title {
        font-size: 20px;
      }
    }
  }
  &_container {
    border-bottom: 1px solid @gray-1;
    margin-bottom: 100px;
  }
}
